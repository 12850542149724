import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { useAtom } from 'jotai'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout'
import Container from 'components/Container'
import Title from 'components/Title'
import BlogPostItemFeatured from 'components/BlogPostItemFeatured'
import BlogPostItem from 'components/BlogPostItem'
import { alternateLanguagesState } from '../atoms/languageSwitcher'
import { metaTitle } from '../utils/page-meta-utils'
import languages from '../i18n'
import { linkResolver } from '../utils/linkResolver'

const BlogPage = ({ data }) => {
  if (!data) return null

  const intl = useIntl()
  const [alternateLanguages, setAlternateLanguages] = useAtom(alternateLanguagesState)

  useEffect(() => {
    setAlternateLanguages(languages.map((language) => ({
      lang: language.prismicLocale,
      skipResolver: true,
      url: `${language.prefix}/${language.slugs.blog}`
    })))
  }, [])

  const posts = [...new Set(data.allPrismicBlogPost.nodes)]
  posts.splice(0, 0, posts[0])

  return (
    <Layout pageName='BlogPage'>
      <Helmet>
        <title>{metaTitle(intl.formatMessage({ id: 'blog.title' }))}</title>
      </Helmet>
      <section className='BlogPage-content'>
        <Container size='small'>
          <Title textAlign='center' className='BlogPage-title'>{ intl.formatMessage({ id: 'blog.title' }) }</Title>
          <ul className='BlogPage-articles'>
            {posts.map((post, i) => {
              const Item = (i === 0) ? BlogPostItemFeatured : BlogPostItem

              return (
                <Item
                  key={ `post-${i}` }
                  url={ linkResolver(post) }
                  title={ post.data.title.text }
                  excerpt={ post.data.excerpt.text }
                  image={ post.data.image.url }
                  category={ post.data.category }
                />
              )
            })}
          </ul>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($lang: String) {
    allPrismicBlogPost(sort: {fields: first_publication_date, order: DESC}, filter: {lang: { eq: $lang }}) {
      nodes {
        first_publication_date
        uid
        lang
        type
        data {
          title {
            text
          }
          excerpt {
            text
          }
          image {
            url
          }
          category {
            document {
              ...on PrismicBlogCategory {
                data {
                  title
                  tag_color_text
                  tag_color_background
                  filter_color_light
                  filter_color_dark
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
